.splash{
  color: $ocre;
  background-color: rgba(237,12,44,1);
  min-height: 100vh;
  
  .main-image{
    display: none;
  }
  
  .content{
    padding: 30px 0;
    .container{
      
      .heading{
        text-align: center;
        margin-bottom: 30px;
        h3{
          font-size: 35px;
          &:last-child{
            font-size: 24px;
          }
        }
        img{
          display: inline-block;
          max-height: 300px;
          margin: 20px 0;
        }
        h2{
          font-size: 28px;
          margin-bottom: 10px;
        }
      }
      
      .links{
        text-align: center;
        margin-bottom: 30px;
        h3{
          margin-bottom: 20px;
          font-size: 16px;
        }
        .wrapper{
          display: grid;
          gap: 20px;
        }
      }
      
      >a{
        text-decoration: underline;
        color: $ocre;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        display: block;
        margin-bottom: 30px;
      }
      
      .newsletter{
        margin-bottom: 10px;
        .form-notice{
          text-align: center;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 9px;
          display: none;
          &.form-error{
            margin-top: 10px
          }
        }
        form{
          @include flex(row,initial,initial);
          gap: 10px;
          .hide{
            display: none;
          }
          label{
            position: absolute;
            z-index: -999999999999999999;
          }
          .form-group{
            flex: 1;
          }
          input{
            background-color: transparent;
            border: solid 1px transparent;
            border-bottom-color: $ocre;
            color: $ocre;
            text-transform: uppercase;
            font-size: 9px;
            padding: 10px;
            font-weight: 700;
            width: 100%;
            transition: all .4s;
            &::placeholder{
              color: $ocre;
            }
            @include hover-focus{
              border-color: $ocre;
              outline: none;
            }
          }
          button{
            flex: 1;
            max-width: max-content;
            padding: 10px 30px;
            @include hover-focus{
              color: $black;
            }
          }
        }
      }
      
      .social{
        margin-bottom: 10px;
        a{
          color: $ocre;
          @include hover-focus{
            color: $ocre;
          }
        }
      }
      
      .copyright{
        text-align: center;
        img{
          display: inline-block;
          margin-bottom: 4px;
          height: 14px;
        }
        p{
          text-transform: uppercase;
          font-size: 8px;
          line-height: 1.4;
          a{
            color: $ocre;
            @include hover-focus{
              color: $white;
            }
          }
        }
      }
    }
  }

  @include tablet-up{
    @include flex(row,space-between,initial);
    .main-image{
      display: block;
      width: 50%;
      object-fit: cover;
      object-position: top;
      max-height: 100vh;
    }
    .content{
      width: 50%;
      @include flex(column,center,initial);
      .container{
        .heading{
          margin-bottom: 50px;
          img{
            display: none;
          }
          h3{
            font-size: 24px;
            margin-bottom: 20px;
          }
          h2{
            display: block;
            font-size: 56px;
            margin-bottom: 30px;
            span{
              margin-top: 10px;
              display: block;
              font-size: 23px;
            }
          }
        }

        .links{
          margin-bottom: 50px;
          .wrapper{
            grid-template-columns: repeat(2, 1fr);
          }
        }

        >a{
          margin-bottom: 50px;
        }

        .newsletter{
          max-width: 400px;
          margin: 0 auto 20px;
        }
        .social{
          margin-bottom: 20px;
        }

        .copyright{
          max-width: 500px;
          margin: 0 auto;
        }
      }
    }
  }

}