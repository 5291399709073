.home{

  header{
    padding: 30px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    background: rgb(145,8,93);
    background: linear-gradient(0deg, rgba(145,8,93,0) 0%, rgba(145,8,93,1) 100%);
    h1{
      text-align: center;
      font-size: 24px;
    }
    .nav{
      @include flex(row,center,center);
      gap: 40px;
      list-style: none;
      padding: 0;
      li{
        a{
          font-size: 18px;
          text-transform: uppercase;
          @include hover-focus{
            text-decoration: underline;
          }
        }
      }
    }

    @include tablet-up{
      .container{
        @include flex(row,space-between, flex-start);
      }
      .top{
        @include flex(column,flex-start,flex-start);
        h1{
          font-size: 26px
        }
        .nav{
          @include flex(column,flex-start,flex-start);
          gap: 15px;
        }
      }
    }
  }
  main{
    position: relative;
    padding: 100px 0 100px;
    .bg{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .content{
      position: relative;
      section{
        padding: 100px 0;
        .container{
          h2{
            text-align: center;
            text-transform: uppercase;
            font-size: 32px;
            margin-bottom: 30px;
          }
        }

        &#music{
          .container{
            .slider-music{
              margin-bottom: 40px;
              .hide{
                display: none;
              }
              .slick-arrow{
                top: calc(50% - 46px);
              }
              a{
                position: relative;
                &::after{
                  content: 'Listen Now';
                  background-color: rgba(0, 0, 0, 0.3);
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: calc(100% - 26px);
                  @include flex(row,center,center);
                  font-weight: 700;
                  text-transform: uppercase;
                  transition: all .4s;
                  opacity: 0;
                }
                img{
                  margin: 0 auto 10px;
                  width: 100%;
                }
                p{
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 16px;
                  text-align: center;
                }
                @include hover-focus{
                  &::after{
                    opacity: 1;
                  }
                }
              }
            }
            >a{
              display: block;
              text-align: center;
              font-weight: 700;
              text-transform: uppercase;
              text-decoration: underline;
              font-size: 16px;
            }
          }
        }

        &#video{
          .container{
            .slider-videos{
              margin-bottom: 40px;
              .video{
                position: relative;
                img{
                  width: 100%;
                }
                p{
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 14px;
                  text-align: center;
                  position: absolute;
                  bottom: 10px;
                  left: 10px;
                }
                i{
                  position: absolute;
                  top: calc(50% - 30px);
                  left: calc(50% - 30px);
                  border-radius: 40px;
                  width: 60px;
                  height: 60px;
                  @include flex(row,center,center);
                  color: $white;
                  font-size: 30px;
                  border: solid 1px $white;
                  &::before{
                    transform: translateX(3px);
                  }
                }
              }
            }
            >a{
              display: block;
              text-align: center;
              font-weight: 700;
              text-transform: uppercase;
              text-decoration: underline;
              font-size: 16px;
            }
            .video-popup{
              position: fixed;
              z-index: 99999999999;
              top: 0;
              left: 0;
              background-color: $black;
              height: 100vh;
              width: 100%;
              transition: all .4s;
              opacity: 0;
              pointer-events: none;
              &.active{
                opacity: 1;
                pointer-events: all;
              }
              .container{
                height: 100vh;
                @include flex(column,center,center);
                .wrapper{
                  width: 100%;
                  @include flex(column,center,center);
                  button{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    @include flex(row,center,center);
                    border: none;
                    font-size: 20px;
                    background-color: $white;
                  }
                  iframe{
                    aspect-ratio: 16 / 9;
                    width: 100%;
                    max-width: 700px;
                  }
                }
              }
            }
          }
        }

        &#tour{
          .container{
            #events{
              @include flex(column,center,center);
              gap: 40px;
              .event{
                @include flex(column,center,center);
                text-transform: uppercase;
                font-size: 16px;
                .event-date{
                  font-weight: 700;
                  margin-bottom: 10px;
                }
                .event-location{
                  font-weight: 700;
                  margin-bottom: 4px;
                }
                .event-links{
                  margin-top: 10px;
                  display: grid;
                  gap: 10px;
                  .btn{
                    color: $white;
                    border-color: $white;
                    font-size: 16px;
                    @include hover-focus{
                      background-color: $white;
                      color: $black;
                    }
                  }
                }
              }
            }
          }

          @include tablet-up{
            .container{
              #events{
                gap: 60px;
                .event{
                  flex-direction: row;
                  gap: 20px;
                  width: 100%;
                  max-width: 800px;
                  line-height: 1.4;
                  .event-date{
                    margin: 0;
                    flex: 1;
                    max-width: max-content;
                    text-align: left;
                  }
                  .event-location{
                    margin: 0;
                    flex: 1;
                    text-align: left;
                  }
                  .event-venue{
                    margin: 0;
                    flex: 1;
                    text-align: left;
                  }
                  .event-links{
                    margin: 0;
                    flex: 1;
                  }
                }
              }
            }
          }
        }

        &#newsletter{
          .container{
            .newsletter-form{
              form{
                max-width: 400px;
                margin: 0 auto;
                label{
                  position: absolute;
                  left: -99999px;
                }
                input{
                  width: 100%;
                  border: solid 1px transparent;
                  transition: all .4s;
                  border-bottom-color: $white;
                  background-color: transparent;
                  text-transform: uppercase;
                  font-weight: 700;
                  font-size: 20px;
                  color: $white;
                  padding: 10px;
                  text-align: center;
                  &::placeholder{
                    color: $white;
                  }
                  @include hover-focus{
                    outline: none;
                    border-color: $white;
                  }
                }
                button{
                  border-color: $white;
                  color: $white;
                  font-size: 20px;
                  margin: 20px auto 0;
                  padding-top: 11px;
                  @include hover-focus{
                    background-color: $white;
                    color: $black;
                  }
                }
              }
              .home-form-notice{
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                display: none;
                &.home-form-error{
                  margin-top: 10px;
                }
              }
            }
          }

          @include tablet-up{
            margin-bottom: 250px;
            .container{
              h2{
                font-size: 70px;
              }
            }
          }
        }

        &.footer{
          text-align: center;
          img{
            display: inline-block;
          }
          .copyright{
            p{
              font-size: 8px;
              text-transform: uppercase;
              line-height: 1.4;
            }
          }
          @include tablet-up{
            display: none;
          }
        }


      }
    }
  }

  footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    .bg{
      width: 100%;
    }
    .container{
      display: none;
    }

    @include tablet-up{
      .container{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        padding: 0 20px 20px 20px;
        @include flex(row,space-between,flex-end);
        .copyright{
          p{
            font-size: 8px;
            max-width: 400px;
            text-align: right;
          }
        }
      }
    }
  }
}