@import "variables";
@import "framework/framework";
@import "splash";
@import "home";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'Neue Plak';
	src: url('../../dist/font/NeuePlak-ExtendedSemiBold.woff2') format('woff2'),
			url('../../dist/font/NeuePlak-ExtendedSemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Plak';
	src: url('../../dist/font/NeuePlak-ExtendedExtraBlack.woff2') format('woff2'),
			url('../../dist/font/NeuePlak-ExtendedExtraBlack.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Plak';
	src: url('../../dist/font/NeuePlak-ExtendedRegular.woff2') format('woff2'),
			url('../../dist/font/NeuePlak-ExtendedRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: 'Neue Plak', Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

h1,h2,h3,h4,p{
	margin: 0;
}

.btn{
	display: block;
	padding: 15px 30px;
	border: solid 1px $ocre;
	color: $ocre;
	text-align: center;
	background-color: transparent;
	text-transform: uppercase;
	color: $ocre;
	font-weight: 700;
	font-size: 9px;
	img{
		display: inline-block;
		transition: all .4s;
	}
	@include hover-focus{
		background-color: $ocre;
		img{
			filter: brightness(0.5);
		}
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.slick-slider{
	padding: 0 50px;
	position: relative;
	.slick-arrow{
		padding: 0;
		border: none;
		background-color: transparent;
		color: $white;
		font-size: 40px;
		position: absolute;
		top: calc(50% - 20px);
		z-index: 9;
		&.slick-prev{
			left: 0;
		}
		&.slick-next{
			right: 0;
		}
	}
	@include tablet-up{
		.slick-slide {
      margin: 0 30px;
			.slick-list {
					margin: 0 -30px;
			}
  	}
	}
}

header {

}

main {

	

}

footer {

}

.off{
	position: fixed !important;
	opacity: 0 !important;
	pointer-events: none !important;
	width: 100%;
}